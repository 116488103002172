const CLASS_SELECTOR = ".Slideshow";
const CHILD_SELECTOR = ".SlideshowSlide";
const ACTIVE_FLAG = "is-active";

let nextIndex = 0;

const Slideshow = {
    elements: null,
    handlers: {},

    init: (PageComponents, container=null) => {
        if (!container || !container.querySelectorAll) {
            container = document;
        }
        Slideshow.elements = container.querySelectorAll(CLASS_SELECTOR);
        window.forEachNode(Slideshow.elements, (element) => {
            const children = element.querySelectorAll(CHILD_SELECTOR);
            const setSlide = (index) => {
                index = parseInt(index);
                if (children.length < 1) return;
                if (index < 0) index = children.length - 1;
                if (index >= children.length) index = 0;
                for (let i = 0; i < children.length; i ++) {
                    children[i].classList.toggle(ACTIVE_FLAG, i === index);
                }
            };

            Slideshow.handlers[element.getAttribute("id") || nextIndex++] = {
                element,
                children,
                setSlide
            };
        });

        PageComponents.Slideshow = Slideshow;
    },
};

module.exports = Slideshow;
