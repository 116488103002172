const CLASS_SELECTOR = ".Lightbox";
const CONTAINER_SELECTOR = ".Lightbox-slides";
const PREV_SELECTOR = ".Lightbox-prev";
const NEXT_SELECTOR = ".Lightbox-next";
const CLOSER_SELECTOR = ".Lightbox-close";
const DEFAULT_LINK_SELECTOR = ".LightboxLink";

const SLIDE_ROOT_SELECTOR = ".Lightbox-slide";
const SLIDE_IMAGE_SELECTOR = ".Lightbox-slideImage";

const lightboxes = [];
let initialized = false;

function initSingle(rootElement) {
    const LinkSelector = rootElement.getAttribute("data-link-selector");
    const SlideTemplate = rootElement.querySelector('#lightbox-slide');

    let links;
    let slides = [];
    let activeIndex;

    if (LinkSelector) {
        links = document.querySelectorAll(LinkSelector);
    } else {
        links = document.querySelectorAll(DEFAULT_LINK_SELECTOR);
    }

    if (links) {
        const container = rootElement.querySelector(CONTAINER_SELECTOR);

        window.forEachNode(links, (link, index) => {
            const url = link.getAttribute("href") || link.getAttribute("data-url");
            const slide = SlideTemplate.content.cloneNode(true);
            const slideElement = slide.querySelector(SLIDE_ROOT_SELECTOR);
            slideElement.setAttribute("data-index", index);
            slideElement.href = url;
            slideElement.addEventListener('click', e => e.stopPropagation());
            const imageElement = slide.querySelector(SLIDE_IMAGE_SELECTOR);
            imageElement.src = url;
            container.appendChild(slide);

            function toggle(activeElement) {
                slideElement.classList.toggle('is-active', slideElement === activeElement);
            }

            function click(e) {
                if (e) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                if (!rootElement.classList.contains('is-active')) {
                    rootElement.classList.toggle('is-active', true);
                    Warframe.lockPageScrolling();
                }
                slides.forEach((s) => s.toggle(slideElement));
                activeIndex = index;
            }

            link.addEventListener('click', click);

            slides.push({
                index,
                slide,
                slideElement,
                toggle,
                click
            });
        });

        function prevSlide(e) {
            e && e.stopPropagation();
            activeIndex --;
            if (activeIndex < 0) activeIndex = links.length - 1;
            slides[activeIndex].click();
        }
        function nextSlide(e) {
            e && e.stopPropagation();
            activeIndex ++;
            if (activeIndex >= links.length) activeIndex = 0;
            slides[activeIndex].click();
        }
        function close() {
            rootElement.classList.toggle('is-active', false);
            Warframe.unlockPageScrolling();
        }

        window.addEventListener("keydown", (e) => {
            if (!e.defaultPrevented && e.key === "Escape" && rootElement.classList.contains("is-active")) {
                close();
            }
        });

        rootElement.querySelector(PREV_SELECTOR).addEventListener('click', prevSlide);
        rootElement.querySelector(NEXT_SELECTOR).addEventListener('click', nextSlide);
        rootElement.querySelector(CLOSER_SELECTOR).addEventListener('click', close);
        rootElement.addEventListener('click', close);

        lightboxes.push({
            slides,
            activeIndex,
        });
    }
}

function init(PageComponents, container = null) {
    if (!initialized) {
        const lightboxElements = document.querySelectorAll(CLASS_SELECTOR);
        // Singleton
        if (lightboxElements) {
            window.forEachNode(lightboxElements, (rootElement) => {
                initSingle(rootElement);
            });
            initialized = true;
        }
    }
}

module.exports = {
    lightboxes,
    init
};
