import Vue from "vue/dist/vue.esm.js";
import {EventBus} from "../../utility/vue-event-bus";
import {gsap} from "gsap";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

window.app = window.app || {};
window.app.duviri = window.app.duviri || {};

const PARENT_CLASS_SELECTOR = "#duviri-gameplay",
    CHILD_CLASS_SELECTOR = ".card",
    ARTIFACT_ACTIVE_CLASS = "discovered",
    REDEMPTION_API_URL = "/api/duviri-redemption",
    PAGE_STATES = {
        "DEFAULT": 0,
        "REDEMPTION_QUEUED": 1,
        "REDEMPTION_ERROR": 2,
        "REDEEMED": 3
    },
    WINDOW_OBJECT = window.app.duviri,
    GAME_SUCCESS_TRANSITION_DELAY = 666;

let PAGE_STATE = PAGE_STATES.DEFAULT;

if ( WINDOW_OBJECT.hasOwnProperty( "redemption" ) ) {
    // console.log( "redemption data:", { WINDOW_OBJECT, "status": WINDOW_OBJECT.redemption.status } );
    switch ( WINDOW_OBJECT.redemption.status ) {
        case "redeemed":
            PAGE_STATE = PAGE_STATES.REDEEMED;
            break;
        case "queued":
            PAGE_STATE = PAGE_STATES.REDEMPTION_QUEUED;
            break;
        case "error":
            PAGE_STATE = PAGE_STATES.REDEMPTION_ERROR;
            break;
    }
}


/*
    const STATUS_QUEUED = 'queued';
    const STATUS_REDEEMED = 'redeemed';
    const STATUS_ERROR = 'error';
    const ERROR_DUPLICATE = 'already_have';
    const ERROR_NOT_UPDATED = 'no_update';
    const ERROR_TRANSACTION = 'transaction_error';
*/


const Artifacts = [
    {
        reference: 100,
        section: "world",
        elementSelector: ".SectionContent"
    },
    {
        reference: 101,
        section: "story",
        elementSelector: ".cards .card:nth-child( 2 ) figure"
    },
    {
        reference: 102,
        section: "story",
        elementSelector: ".cards .card:nth-child( 3 ) figure"
    },
    {
        reference: 300,
        section: "store",
        elementSelector: ".Section-content .card.spotlight"
    },
    {
        reference: 400,
        section: "footer",
        elementSelector: ".Section-wrapper"
    }
];
let activeArtifacts = [];

const VM_FlashAlert = {
        name: "FlashAlert",
        template: "#minigame-error",
        methods: {
            onClickClose ( event ) {
                this.$emit( "close" );
            }
        }
    },
    VM_ScoreCard = {
        name: "ScoreCard",
        data() {
            return {
                counters: [],
                discovered: [],
                gameIsOpen: false
            };
        },
        computed: {
            isComplete() {
                return ( this.counters.length === this.discovered.length );
            }
        },
        methods: {
            getHeaderOffset() {
                let elements = ["#header", ".Sticky.is-fixed"],
                    height = 0;
                elements.forEach((string) => {
                    let element = document.querySelector(string);
                    if (!!element) {
                        height += element.getBoundingClientRect().height;
                    }
                });
                return height;
            },
            onClickScore(event) {
                if (!this.isComplete) {
                    return;
                }
                let self = this;

                gsap.to(window, {
                    // scrollTo: { y: 0 },
                    scrollTo: {y: "#duviri-tumbler-game", offsetY: self.getHeaderOffset},
                    onComplete() {
                        self.$nextTick(() => {
                            if (!self.gameIsOpen) {
                                EventBus.$emit("open-game");
                                EventBus.$emit( "recalculate-parallax" );
                                self.gameIsOpen = true;
                            }
                        });
                    }
                });
            },
            isDiscovered(reference) {
                // console.log("isDiscovered", {
                //     reference,
                //     test: (this.discovered.indexOf(reference.toString()) !== -1),
                //     index: this.discovered.indexOf(reference.toString())
                // });
                return this.discovered.indexOf(reference.toString()) !== -1;
            },
            onReturnUser() {
                let self = this;
                self.discovered = [ ...self.counters ];
                self.gameIsOpen = true; // right?
                self.$nextTick( () => {
                    EventBus.$emit( "recalculate-parallax" );
                } );

            }
        },
        created() {
            let self = this;
            self.counters = activeArtifacts.map(({reference}) => reference);
            EventBus.$on("artifact-discovered", function (value) {
                self.discovered.push(value);
            });
            EventBus.$on("return-user", this.onReturnUser );
        },
    },
    VM_GameSection = {
        name: "GameSection",
        el: "#duviri-tumbler-game",
        data() {
            return {
                gameHasBeenSeen: false,
                showGame: false,
                DuviriHackGame: null,
                gameSuccess: false,
                gameFailed: false,
                pollingTimeout: null,
                soundIsOn: true,
                devOverride: false,
                userRedemptionAPIResponse: null,
                isReturningVisitor: false,
                showError: false,
                gameRevealDuration: 2000 // an excessive default
            };
        },
        components: {
            "FlashAlert": VM_FlashAlert
        },


        computed: {
            isRedeemed() {
                // {"error":"already_have","success":false}
                return this._isStateOrApiResponse(PAGE_STATES.REDEEMED, 'success') || this._isStateOrApiResponse(PAGE_STATES.REDEMPTION_QUEUED, 'error', 'already_have');
            },
            isQueued() {
                return this._isStateOrApiResponse(PAGE_STATES.REDEMPTION_QUEUED, 'error', 'not_logged_in') && !this.isRedeemed;
            },
            isRedemptionError() {
                return this._isStateOrApiResponse(PAGE_STATES.REDEMPTION_ERROR, 'error', null, 'not_logged_in') && this._isStateOrApiResponse(PAGE_STATES.REDEMPTION_ERROR, 'error', null, 'already_have');
            },
            userIsLoggedIn() {
                return !!WINDOW_OBJECT.user?.isLoggedIn;
            },
        },

        methods: {
            _isStateOrApiResponse(pageState, responseProp, responseValue = true, responseValueNot = null) {
                const setByPage = PAGE_STATE === pageState;
                const setByApi = !!this.userRedemptionAPIResponse &&
                    this.userRedemptionAPIResponse.hasOwnProperty(responseProp) &&
                    (responseValueNot === null ?
                        this.userRedemptionAPIResponse[responseProp] === responseValue :
                        this.userRedemptionAPIResponse[responseProp] !== responseValueNot);
                return setByApi || setByPage;
            },
            devTriggerGameSuccess() {
                this.onGameSuccess();
                this.devOverride = true;
            },

            expandOnEnter(element) {
                // console.log('expandOnEnter', {element});
                let width = getComputedStyle(element).width,
                    height;

                element.style.width = width;
                element.style.position = 'absolute';
                element.style.visibility = 'hidden';
                element.style.height = 'auto';

                height = getComputedStyle(element).height;


                element.style.width = null;
                element.style.position = null;
                element.style.visibility = null;
                element.style.height = 0;

                // Force repaint to make sure the
                // animation is triggered correctly.
                getComputedStyle(element).height;

                // Trigger the animation.
                // We use `requestAnimationFrame` because we need
                // to make sure the browser has finished
                // painting after setting the `height`
                // to `0` in the line above.
                requestAnimationFrame(() => {
                    element.style.height = height;
                });
            },
            expandOnAfterEnter(element) {
                // console.log('expandOnAfterEnter', {element});
                element.style.height = 'auto';
            },
            expandOnLeave(element) {
                // console.log('expandOnLeave', {element});
                let height = getComputedStyle(element).height;

                element.style.height = height;

                // Force repaint to make sure the
                // animation is triggered correctly.
                getComputedStyle(element).height;

                requestAnimationFrame(() => {
                    element.style.height = 0;
                });
            },
            onClickToggleSound(event) {
                this.DuviriHackGame.set_volume(this.soundIsOn ? 0 : 1);
                this.soundIsOn = !this.soundIsOn;
            },
            getCSSPropertyValue(element, property) {
                let style = window.getComputedStyle(element),
                    value = style.getPropertyValue(property);
                if (isNaN(value)) {
                    return value;
                }
                return parseFloat(value);
            },
            startGame() {
                if (this.gameHasBeenSeen) {
                    this.DuviriHackGame.start_prevent_reset();
                    return;
                }
                this.gameHasBeenSeen = true;
                this.DuviriHackGame.start();
            },
            stopGame() {
                clearTimeout(this.pollingTimeout);
                this.DuviriHackGame.stop();
            },
            onClickStop(event) {
                // console.log("onClickStop");
                this.DuviriHackGame.stop();
            },
            onClickStart(event) {
                // console.log("onClickStart");
                this.DuviriHackGame.start();
            },
            onRedemptionAPIResponse() {},

            submitAPIRequest() {
                fetch ( REDEMPTION_API_URL, {
                    method: "POST",
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                } )
                    .then( response => response.json() )
                    .then( data => this.onAPISuccess(data) )
                    .catch( error => this.onAPIError(error) );
            },
            onAPISuccess(data) {
                // handle successful response here
                // console.log('API response:', data);
                this.userRedemptionAPIResponse = data;
                this.$nextTick( () => {
                    this.showError = this.isRedemptionError;
                } );
            },
            onAPIError(error) {
                // handle error response here
                console.error('API error:', error);
                this.showError = true;
            },

            onGameSuccess() {
                // console.log("onGameSuccess", {gameState: this.DuviriHackGame.get_game_state()});
                setTimeout( ()=>{
                    this.gameFailed = false;
                    this.gameSuccess = true;

                    this.DuviriHackGame.set_volume( 0 );
                    this.soundIsOn = false;

                    this.$nextTick( () => {
                        EventBus.$emit( "recalculate-parallax" );
                    } );

                    this.submitAPIRequest();
                }, GAME_SUCCESS_TRANSITION_DELAY);
            },
            onInitGame() {
                let self = this;
                document.addEventListener("visibilitychange", () => {
                    if (!this.gameHasBeenSeen) { return; }
                    if (this.gameSuccess) { return; }
                    // example of pausing the game when tabbed out
                    if (document.visibilityState === "visible") {
                        // using start_prevent_reset() instead of start() bypasses reset checks
                        // this is useful for resuming from a 'pause' rather than user-initiated close
                        self.DuviriHackGame.start_prevent_reset();
                    } else {
                        self.DuviriHackGame.stop();
                    }
                });
            },
            onCloseAlert() {
                this.showError = false;
            }
        },
        created() {
            let self = this;
            EventBus.$on("open-game", function () {
                self.showGame = true;
            });
            EventBus.$on("return-user", function () {
                self.isReturningVisitor = true;
                self.showGame = true;
                self.gameFailed = false;
                self.gameSuccess = true;
                self.$nextTick( () => {
                    setTimeout( ()=>{
                        EventBus.$emit( "recalculate-parallax" );
                    }, self.gameRevealDuration );
                } );

            } );
        },
        mounted() {
            let self = this;
            // console.log(`Vue Mounted ${this.$options.name}`, {duvirihack, options: WINDOW_OBJECT.config});

            self.gameRevealDuration = parseFloat(this.getCSSPropertyValue(this.$refs['gameContainer'], 'transition-duration')) * 1000;


            if ( self.isRedemptionError ) {
                self.showError = true;
            }

            if (!!duvirihack && !!WINDOW_OBJECT.config) {
                self.DuviriHackGame = duvirihack;
                WINDOW_OBJECT.game = this.DuviriHackGame;

                WINDOW_OBJECT.config.failure_callback = ( attempt_count, click_count )=>{
                    // console.log( "game failure", { attempt_count, click_count } );
                    self.gameFailed = true;
                };
                WINDOW_OBJECT.config.success_callback = ( attempt_count, click_count, time_left )=>{
                    // console.log( "game success", { attempt_count, click_count, time_left } );
                    self.onGameSuccess();

                };
                WINDOW_OBJECT.config.restart_callback = ( attempt_count )=>{
                    // console.log( "game restart", { attempt_count } );
                    self.gameFailed = false;
                };

                this.DuviriHackGame.init(WINDOW_OBJECT.config).then(() => {
                    this.DuviriHackGame.set_volume( 0.2 );

                    self.onInitGame();
                });
            }

        },
        watch: {
            showGame(newValue, oldValue) {
                let self = this,
                    delay;
                // console.log({'game state': this.DuviriHackGame.get_game_state(), newValue, oldValue});
                if ( self.isReturningVisitor ) {
                    return;
                }
                if (!newValue) {
                    this.stopGame();
                    return;
                }

                self.$nextTick( () => {
                    self.startGame();
                    setTimeout( ()=>{
                        EventBus.$emit( "recalculate-parallax" );
                    }, self.gameRevealDuration );
                } );

            }
        }
    };

const DuviriArtifactHunt = {
    initialized: false,
    elements: [],
    discoveredArtifacts: [],

    buildArtifact({reference, discovered}) {
        let elArtifact = document.createElement('div');
        elArtifact.setAttribute("data-artifact", reference);
        if (discovered) {
            elArtifact.classList.add(ARTIFACT_ACTIVE_CLASS);
        }
        return elArtifact;
    },

    insertArtifactElements() {
        activeArtifacts.forEach((artifact, index) => {
            let elContainer = document.querySelector(`#duviri-${artifact.section}`),
                elTarget = elContainer.querySelector(artifact.elementSelector),
                elArtifact = DuviriArtifactHunt.buildArtifact(artifact);

            // console.log({artifact, elContainer, elTarget, el: DuviriArtifactHunt.buildArtifact(artifact)});

            elTarget.appendChild(elArtifact);

            elArtifact.addEventListener("click", DuviriArtifactHunt.onClickArtifact);
        });
    },

    onClickArtifact(event) {
        const SELECTOR = "[ data-artifact ]";
        let elTarget = event.target.closest(SELECTOR),
            referenceValue = elTarget.getAttribute(SELECTOR.split(" ")[1]); // yuck

        EventBus.$emit("artifact-discovered", referenceValue);

        elTarget.classList.add(ARTIFACT_ACTIVE_CLASS);
        elTarget.removeEventListener("click", DuviriArtifactHunt.onClickArtifact);
    },

    initVueComponents() {
        let gameContainer,
            scoreCards = [];

        // Game Section, of which there is only ever one
        gameContainer = new Vue(VM_GameSection);

        // Score cards, of which there are at least two :(
        [...document.querySelectorAll(".score[ data-vue-component ]")].forEach(element => {
            let viewModel = {...VM_ScoreCard};
            viewModel.el = element;
            scoreCards.push(new Vue(viewModel));
        });

    },

    selectActiveArtifacts() {
        let artifactsSubset = DuviriArtifactHunt.getRandomObjects(Artifacts, 3);
        // let artifactsSubset = DuviriArtifactHunt.getRandomObjects( Artifacts, 5 );
        // console.log( { artifactsSubset } );
        activeArtifacts = artifactsSubset;
    },

    getRandomObjects(array, number) {
        const result = [],
            length = array.length,
            _number = ( number > length ) ? length : number;

        while (result.length < _number) {
            const randomIndex = Math.floor(Math.random() * length);
            const randomObject = array[randomIndex];

            if (!result.includes(randomObject)) {
                result.push(randomObject);
            }
        }

        return result;
    },

    init: (PageComponents, container = null) => {
        if (!container || !container.querySelectorAll) {
            container = document;
        }


        // randomly pick three Artifacts
        DuviriArtifactHunt.selectActiveArtifacts();

        DuviriArtifactHunt.initVueComponents();

        if ( PAGE_STATE !== PAGE_STATES.DEFAULT ) {
            // console.log( `%cRETURN USER`, `font-size: 28px; font-weight: 700; color: #ff00ff;` );
            EventBus.$emit( "return-user" );
            // define elements

        } else {
            // console.log( `%cDEFAULT USER`, `font-size: 28px; font-weight: 700; color: #55ff55;` );
            DuviriArtifactHunt.insertArtifactElements();
        }

        if (!DuviriArtifactHunt.initialized) {
            DuviriArtifactHunt.initialized = true;
        }

        PageComponents.DuviriArtifactHunt = DuviriArtifactHunt;
    }

};

export {DuviriArtifactHunt};
