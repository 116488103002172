//
import {Tennocon2024} from "../content/tennocon2024";

const SELECTOR_CONTAINER_NAME = ".Entry",
    ATTRIBUTE_TOOLTIP = "data-tooltip",
    mobileMatchList = window.matchMedia("(max-width: 767px)");

// Global tooltip offset variables,
// Negative numbers move the tooltip towards (even OVER) the trigger; positive, away
let tooltipXOffset = -10,
    tooltipYOffset = -10;

/*
// TODO: Update example
    // Example
    // the trigger
    <a data-dialog="#example-dialog">Click this to open the dialog</a>

    // the dialog
    <dialog id="example-dialog">
        <button data-dialog-close>Close</button>
        Hello!
    </dialog>
*/

const ResponsiveTooltip = {
    triggers: null,

    findTooltip(element) {
        let commonParent = element.closest( SELECTOR_CONTAINER_NAME ) || null,
            reference,
            tooltip;
        if (!!commonParent) {
            tooltip = commonParent.querySelector(`[ ${ATTRIBUTE_TOOLTIP} ]`) || null;
        } else {
            reference = element.getAttribute( `${ATTRIBUTE_TOOLTIP}-trigger` );

            tooltip = document.querySelector( `[ ${ ATTRIBUTE_TOOLTIP }="${ reference }" ]` );

            // console.log( "No Common parent, look for reference", { reference, tooltip } );
        }
        return tooltip;
    },

    getTooltipPosition(triggerElement, tooltipElement) {
        // Temporarily show the tooltip off-screen to measure its dimensions
        tooltipElement.style.visibility = "hidden";
        tooltipElement.style.display = "block";
        tooltipElement.style.position = "fixed";
        tooltipElement.style.top = "0";
        tooltipElement.style.left = "0";

        const triggerRect = triggerElement.getBoundingClientRect(),
            tooltipRect = tooltipElement.getBoundingClientRect(),
            viewportWidth = window.innerWidth,
            viewportHeight = window.innerHeight;

        // Re-hide the tooltip
        tooltipElement.style.visibility = "";
        tooltipElement.style.display = "";
        tooltipElement.style.position = "";
        tooltipElement.style.top = "";
        tooltipElement.style.left = "";

        // Define possible positions and their conditions
        const positions = [
            {
                name: "top-right",
                top: triggerRect.top - tooltipRect.height + tooltipYOffset * -1,
                left: triggerRect.right + tooltipXOffset,
                fits: () =>
                    triggerRect.top >= tooltipRect.height &&
                    triggerRect.right + tooltipRect.width <= viewportWidth,
                overflow: () => {
                    const overflowTop = Math.max(0, tooltipRect.height - triggerRect.top);
                    const overflowRight = Math.max(0, tooltipRect.right - viewportWidth);
                    return overflowTop + overflowRight;
                }
            },
            {
                name: "center-right",
                top: triggerRect.top + triggerRect.height / 2 - tooltipRect.height / 2,
                left: triggerRect.right + tooltipXOffset,
                fits: () =>
                    triggerRect.top + triggerRect.height / 2 - tooltipRect.height / 2 >=
                    0 &&
                    triggerRect.top + triggerRect.height / 2 + tooltipRect.height / 2 <=
                    viewportHeight &&
                    triggerRect.right + tooltipRect.width <= viewportWidth,
                overflow: () => {
                    const overflowTop = Math.max(0, -tooltipRect.top);
                    const overflowBottom = Math.max(0, tooltipRect.bottom - viewportHeight);
                    const overflowRight = Math.max(0, tooltipRect.right - viewportWidth);
                    return overflowTop + overflowBottom + overflowRight;
                }
            },
            {
                name: "bottom-right",
                top: triggerRect.bottom + tooltipYOffset,
                left: triggerRect.right + tooltipXOffset,
                fits: () =>
                    triggerRect.bottom + tooltipRect.height <= viewportHeight &&
                    triggerRect.right + tooltipRect.width <= viewportWidth,
                overflow: () => {
                    const overflowBottom = Math.max(0, tooltipRect.bottom - viewportHeight);
                    const overflowRight = Math.max(0, tooltipRect.right - viewportWidth);
                    return overflowBottom + overflowRight;
                }
            },
            {
                name: "bottom-center",
                top: triggerRect.bottom + tooltipYOffset,
                left: triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2,
                fits: () =>
                    triggerRect.bottom + tooltipRect.height <= viewportHeight &&
                    triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2 >= 0 &&
                    triggerRect.left + triggerRect.width / 2 + tooltipRect.width / 2 <=
                    viewportWidth,
                overflow: () => {
                    const overflowBottom = Math.max(0, tooltipRect.bottom - viewportHeight);
                    const overflowLeft = Math.max(0, -tooltipRect.left);
                    const overflowRight = Math.max(0, tooltipRect.right - viewportWidth);
                    return overflowBottom + overflowLeft + overflowRight;
                }
            },
            {
                name: "bottom-left",
                top: triggerRect.bottom + tooltipYOffset,
                left: triggerRect.left - tooltipRect.width + tooltipXOffset * -1,
                fits: () =>
                    triggerRect.bottom + tooltipRect.height <= viewportHeight &&
                    triggerRect.left >= tooltipRect.width,
                overflow: () => {
                    const overflowBottom = Math.max(0, tooltipRect.bottom - viewportHeight);
                    const overflowLeft = Math.max(0, -tooltipRect.left);
                    return overflowBottom + overflowLeft;
                }
            },
            {
                name: "center-left",
                top: triggerRect.top + triggerRect.height / 2 - tooltipRect.height / 2,
                left: triggerRect.left - tooltipRect.width + tooltipXOffset * -1,
                fits: () =>
                    triggerRect.top + triggerRect.height / 2 - tooltipRect.height / 2 >=
                    0 &&
                    triggerRect.top + triggerRect.height / 2 + tooltipRect.height / 2 <=
                    viewportHeight &&
                    triggerRect.left >= tooltipRect.width,
                overflow: () => {
                    const overflowTop = Math.max(0, -tooltipRect.top);
                    const overflowBottom = Math.max(0, tooltipRect.bottom - viewportHeight);
                    const overflowLeft = Math.max(0, -tooltipRect.left);
                    return overflowTop + overflowBottom + overflowLeft;
                }
            },
            {
                name: "top-left",
                top: triggerRect.top - tooltipRect.height + tooltipYOffset * -1,
                left: triggerRect.left - tooltipRect.width + tooltipXOffset * -1,
                fits: () =>
                    triggerRect.top >= tooltipRect.height &&
                    triggerRect.left >= tooltipRect.width,
                overflow: () => {
                    const overflowTop = Math.max(0, tooltipRect.height - triggerRect.top);
                    const overflowLeft = Math.max(0, -tooltipRect.left);
                    return overflowTop + overflowLeft;
                }
            },
            {
                name: "top-center",
                top: triggerRect.top - tooltipRect.height + tooltipYOffset * -1,
                left: triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2,
                fits: () =>
                    triggerRect.top >= tooltipRect.height &&
                    triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2 >= 0 &&
                    triggerRect.left + triggerRect.width / 2 + tooltipRect.width / 2 <=
                    viewportWidth,
                overflow: () => {
                    const overflowTop = Math.max(0, tooltipRect.height - triggerRect.top);
                    const overflowLeft = Math.max(0, -tooltipRect.left);
                    const overflowRight = Math.max(0, tooltipRect.right - viewportWidth);
                    return overflowTop + overflowLeft + overflowRight;
                }
            }
        ];

        // Check each position to see if it fits perfectly
        for (const position of positions) {
            if (position.fits()) {
                return {name: position.name, top: position.top, left: position.left};
            }
        }

        // If no perfect fit, find the position with the least overflow
        let bestPosition = positions.find((p) => p.name === "top-right");
        let minOverflow = bestPosition.overflow();
        for (const position of positions) {
            const overflow = position.overflow();
            if (overflow < minOverflow) {
                minOverflow = overflow;
                bestPosition = position;
            }
        }

        return {
            name: bestPosition.name,
            top: bestPosition.top,
            left: bestPosition.left
        };
    },

    onClickOutsideOfOpenTooltip( event ) {
        // console.log( "onClickOutsideOfOpenTooltip", { event, "context": this, "in tooltip?": this.tooltip.$el.contains(event.target), "in trigger?": this.trigger.$el.contains(event.target), "in INNER tooltip?": this.tooltip.$el.querySelector( ".inner" ).contains( event.target ) } );

        let closeTooltip = false;


        if( ResponsiveTooltip.useMobile() ) {
            // mobile needs to look for the click in the `.inner`
            closeTooltip = ( !this.tooltip.$el.querySelector( ".inner" ).contains( event.target ) && !this.trigger.$el.contains( event.target ) );
        } else {
            // not-mobile can look just for the `tooltip.$el`
            closeTooltip = ( !this.tooltip.$el.contains( event.target ) && !this.trigger.$el.contains( event.target ) );
        }

        if ( closeTooltip ) {
            // Shut. It. down.
            this.tooltip.$el.classList.remove("trigger-clicked", "trigger-hovered", "tooltip-hovered");
            document.body.classList.remove("trigger-clicked", "trigger-hovered", "tooltip-hovered");
            this.unsetExternalClickListener( event );
        } //else {
            // console.log( "CLICK DENIED" );
        //}


    },

    onMouseenterTrigger(event) {
        // console.log(`Trigger ${this.index} Hovered`);
        if( ResponsiveTooltip.useMobile() ) {
            return;
        }
        let self = this;
        self.cooldown = clearTimeout(self.cooldown);
        this.updatePosition();
        self.tooltip.$el.classList.add("trigger-hovered");
        document.body.classList.add("trigger-hovered");

        // set outside click listener
    },
    onMouseleaveTrigger() {
        // console.log(`Trigger ${this.index} UN-Hovered`);
        let self = this;
        self.cooldown = setTimeout(() => {
            self.tooltip.$el.classList.remove("trigger-hovered");
            document.body.classList.remove("trigger-hovered");
            // unset outside click listener
        }, 200);
    },
    onMouseenterTooltip(event) {
        if( ResponsiveTooltip.useMobile() ) {
            return;
        }
        // console.log(`Tooltip ${this.index} Hovered`);
        this.tooltip.$el.classList.add("tooltip-hovered");
        document.body.classList.add("tooltip-hovered");
    },
    onMouseleaveTooltip() {
        // console.log(`Tooltip ${this.index} UN-Hovered`);
        this.tooltip.$el.classList.remove("tooltip-hovered");
        document.body.classList.remove("tooltip-hovered");
    },
    onClickTrigger(event) {
        // console.log(`Trigger ${this.index} Clicked`);
        this.updatePosition();
        if ( this.tooltip.$el.classList.contains( "trigger-clicked" ) ) {
            this.tooltip.$el.classList.remove("trigger-clicked");
            document.body.classList.remove("trigger-clicked");
            // ResponsiveTooltip.unsetExternalClickListener.bind( this )( event );
            this.unsetExternalClickListener( event );

        } else {
            this.tooltip.$el.classList.add("trigger-clicked");
            document.body.classList.add("trigger-clicked");
            // ResponsiveTooltip.setExternalClickListener.bind( this )( event );
            this.setExternalClickListener( event );
        }
    },
    onClickTooltip(event) {
        // console.log(`Tooltip ${this.index} registers a click`, {event});

        let closeButton = event.target.closest(`[ ${ATTRIBUTE_TOOLTIP}-close ]`);
        if (!!closeButton) {
            // console.log(`Tooltip ${this.index} registers a click to its CLOSE button`, {
            //     event
            // });
            this.tooltip.$el.classList.remove("trigger-clicked", "trigger-hovered", "tooltip-hovered");
            document.body.classList.remove("trigger-clicked", "trigger-hovered", "tooltip-hovered");
            // ResponsiveTooltip.unsetExternalClickListener.bind( this )( event );
            this.unsetExternalClickListener( event );
        }
    },
    updateTooltipPosition() {
        let self = this,
            useMobilePosition = ResponsiveTooltip.useMobile(),
            positionData;
        // console.log(`updateTooltipPosition`, {
        //     positionData,
        //     tooltip: self.tooltip.$el,
        //     "use mobile?": useMobilePosition
        // });

        if( useMobilePosition ) {
            self.tooltip.$el.style.top = `0px`;
            self.tooltip.$el.style.left = `0px`;
            return;
        }

        positionData = ResponsiveTooltip.getTooltipPosition(self.trigger.$el, self.tooltip.$el);

        self.tooltip.$el.style.top = `${positionData.top}px`;
        self.tooltip.$el.style.left = `${positionData.left}px`;
    },

    useMobile() {
        return mobileMatchList.matches;
    },

    setExternalClickListener( event ) {
        // console.log( "setExternalClickListener", { event, "context": this, "exists?": this.hasOwnProperty( "externalClickListenerIsSet" ) } );
        let self = this;
        if ( !!self.externalClickListenerIsSet ) {
            return;
        }

        // actually set the listener
        document.addEventListener( "click", self.onClickOutsideOfOpenTooltip, true );


        self.externalClickListenerIsSet = true;
        // console.log( this.externalClickListenerIsSet );

    },

    unsetExternalClickListener( event ) {
        // console.log( "unsetExternalClickListener", { event, "context": this, "exists?": this.hasOwnProperty( "externalClickListenerIsSet" ) } );
        let self = this;
        if ( !self.externalClickListenerIsSet ) {
            return;
        }

        // actually UNset the listener
        document.removeEventListener( "click", self.onClickOutsideOfOpenTooltip, true );

        self.externalClickListenerIsSet = false;
        // console.log( this.externalClickListenerIsSet );

    },

    init() {
        let self = this;
        self.triggers = [...document.querySelectorAll(`button[ ${ATTRIBUTE_TOOLTIP}-trigger ]`)];

        if (!self.triggers.length) {
            return;
        }

        // console.log(`%cResponsiveTooltips`, `font-size: 24px; font-weight: 700; color: red;`);
        // console.log({"triggers": self.triggers});

        ResponsiveTooltip.triggers.forEach((trigger, index) => {
            // console.log({trigger, index, tooltip: ResponsiveTooltip.findTooltip(trigger)});
            let Trigger = {
                trigger: {
                    $el: trigger
                },
                index,
                tooltip: {
                    $el: ResponsiveTooltip.findTooltip(trigger)
                }
            };

            // Short Circuit if missing a component
            if ( !Trigger.tooltip.$el ) {
                // console.log( `%cABORT TOOLTIP ${ index }`, `font-size: 24px; color: red; font-weight: 700;` );
                return;
            }

            Trigger.updatePosition = ResponsiveTooltip.updateTooltipPosition.bind(Trigger);
            Trigger.setExternalClickListener = ResponsiveTooltip.setExternalClickListener.bind(Trigger);
            Trigger.unsetExternalClickListener = ResponsiveTooltip.unsetExternalClickListener.bind(Trigger);
            Trigger.onClickOutsideOfOpenTooltip = ResponsiveTooltip.onClickOutsideOfOpenTooltip.bind(Trigger);


            trigger.addEventListener("click", ResponsiveTooltip.onClickTrigger.bind(Trigger));

            trigger.addEventListener("mouseenter", ResponsiveTooltip.onMouseenterTrigger.bind(Trigger));

            trigger.addEventListener("mouseleave", ResponsiveTooltip.onMouseleaveTrigger.bind(Trigger));

            Trigger.tooltip.$el.addEventListener("click", ResponsiveTooltip.onClickTooltip.bind(Trigger));

            Trigger.tooltip.$el.addEventListener(
                "mouseenter",
                ResponsiveTooltip.onMouseenterTooltip.bind(Trigger)
            );

            Trigger.tooltip.$el.addEventListener(
                "mouseleave",
                ResponsiveTooltip.onMouseleaveTooltip.bind(Trigger)
            );
        });

    }
}

export {ResponsiveTooltip};
