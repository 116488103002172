const CLASS_SELECTOR = ".MobileScroller";
const INNER_SELECTOR = ".MobileScroller-inner";

const MobileScroller = {
    elements: null,
    initialized: false,
    lastAnimationFrame: 0,
    lastAnimationFrames: [],

    update: (element) => {
        if (!element) return;
        const inner = element.querySelector(INNER_SELECTOR);
        let fadeLeft = false;
        let fadeRight = false;
        if (inner.offsetWidth < inner.scrollWidth) {
            fadeLeft = (inner.scrollLeft >= 1);
            fadeRight = (inner.scrollLeft < (inner.scrollWidth - inner.offsetWidth - 1));
        }
        element.classList.toggle('is-fadedLeft', fadeLeft);
        element.classList.toggle('is-fadedRight', fadeRight);
    },

    resizeHandler: () => {
        requestAnimationFrame((timestamp) => {
            if (timestamp > MobileScroller.lastAnimationFrame) {
                MobileScroller.lastAnimationFrame = timestamp;
                window.forEachNode(MobileScroller.elements, (element, index) => {
                    MobileScroller.update(element);
                });
            }
        });
    },
    scrollHandler: (element) => {
        requestAnimationFrame((timestamp) => {
            MobileScroller.update(element);
        });
    },

    initElements: () => {
        window.forEachNode(MobileScroller.elements, (element, index) => {
            if (!element.hasAttribute('data-initialized')) {
                element.querySelector(INNER_SELECTOR).addEventListener('scroll', MobileScroller.scrollHandler.bind(null, element));
                element.setAttribute('data-initialized', 'true');
            }
        });
    },

    init: (PageComponents, container=null) => {
        if (!container || !container.querySelectorAll) {
            container = document;
        }
        MobileScroller.elements = container.querySelectorAll(CLASS_SELECTOR);
        MobileScroller.initElements();
        if (!MobileScroller.initialized) {
            window.addEventListener('resize', MobileScroller.resizeHandler);
            MobileScroller.initialized = true;
        }

        PageComponents.MobileScroller = MobileScroller;
    },

};

module.exports = MobileScroller;
