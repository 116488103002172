import Duviri from "./Duviri";
import { DuviriArtifactHunt } from "./DuviriArtifactHunt";
import { DuviriGameplayParallax } from "./DuviriGameplayParallax";

const ATTRIBUTE_HANDLER_BINDING = "data-handler";

window.Warframe = window.Warframe || {};

const DuviriHubsite = {
    buttons: null,

    initVideoDialog() {
        let trigger, modal, modalVideo;
        trigger = document.querySelector( "[ data-dialog='#gameplay-dialog' ]" );
        modal = document.querySelector( "#gameplay-dialog" );
        modalVideo = modal.querySelector( "video" );

        trigger.addEventListener( "click", ( event ) => {
            event.preventDefault();
            modal.showModal();
            setTimeout( () => {
                modalVideo.play();
            }, 300 );
        } );

        modal.addEventListener( "close", ( event ) => {
            modalVideo.pause();
        } );

        modal.querySelector( "[ data-dialog-close ]" ).addEventListener( "click", ( event ) => {
            event.preventDefault();
            if ( modal.open ) {
                modalVideo.pause();
                modal.close();
            }
        });

    },

    asynchronousAppHandler ( element, appMethodNameString ) {
        element.addEventListener( "click", ( event ) => {
            event.preventDefault();
            if ( !window.Warframe.hasOwnProperty( appMethodNameString ) ) {
                console.debug( `Handler "${ appMethodNameString }" not found`, element );
                return;
            }
            window.Warframe[ appMethodNameString ]();
        } );
    },

    bindClickHandler( element ) {
        let token = element.getAttribute( ATTRIBUTE_HANDLER_BINDING );
        if ( !token ) { return; }
        switch ( token ) {
            case "login":
                DuviriHubsite.asynchronousAppHandler( element, "triggerLoginLightbox" );
                break;
            case "signup":
                DuviriHubsite.asynchronousAppHandler( element, "triggerSignupLightbox" );
                break;
            default:
                console.debug( `Cannot bind handler for token "${ token }"`, element );
        }

    },

    init: (PageComponents, container=null) => {
        if (!container || !container.querySelectorAll) {
            container = document;
        }

        DuviriHubsite.buttons = [ ...document.querySelectorAll( `[${ ATTRIBUTE_HANDLER_BINDING }]` ) ];
        if ( !!DuviriHubsite.buttons ) {
            DuviriHubsite.buttons.forEach( element => { DuviriHubsite.bindClickHandler( element ) } );
        }


        Duviri.init( PageComponents );
        DuviriArtifactHunt.init( PageComponents );
        DuviriGameplayParallax.init( PageComponents );

        DuviriHubsite.initVideoDialog();

        PageComponents.DuviriHubsite = DuviriHubsite;
    },

};

export { DuviriHubsite };
