import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ResponsiveTooltip} from "../../utility/responsive-tooltips";


gsap.registerPlugin(ScrollTrigger);

const SELECTOR_HANDLER_BINDING_ACCORDION = ".tab-content",
    SELECTOR_HANDLER_BINDING_FAQ = ".faq-list .entry",
    SELECTOR_TICKET_UI = ".ticket-ui",
    SELECTOR_SCHEDULE_UI = ".schedule-ui",
    CLASSNAME_ACTIVE = "active";

window.Warframe = window.Warframe || {};

const Tennocon2024 = {
    tabs: null,
    accordions: null,

    getCurrentPageAnchor() {
        // Return the anchor (fragment) without the leading '#'
        return window.location.hash ? window.location.hash.substring(1) : '';
    },
    utilityClearContentSelection( array ) {
        if ( array.length === 1 ) { return; } // this is a proxy for the Digital Packs condition and a really good candidate for refactoring to disambiguate
        array.forEach( ( item ) => {
            item.classList.remove( CLASSNAME_ACTIVE );
            item.removeAttribute( 'hidden' );
        } );
    },
    bindTabBehaviors( element ) {

        // console.log( "bindTabBehaviors" );

        let tabContent = element,
            contentElements = [ ... tabContent.querySelectorAll( ".tab-content" ) ],
            tabElements = [ ... element.querySelectorAll( "button[ role='tab' ]" ) ],
            updatableDisplayElements = [ ... tabContent.querySelectorAll( "[ data-selected ]" )];

        // console.log( "bindTabBehaviors", { element, contentElements, tabElements } );

        if ( !tabElements || !contentElements ) {
            // console.debug( "Tab Ui Fault" );
            return;
        }

        // if a button is preselected as active, let's light up its content (in case it either isn't in the markup OR the default active one ISN'T always the FIRST

        let defaultSelection = tabElements.find( element => element.classList.contains( CLASSNAME_ACTIVE ) ),
            defaultIndex = ( !!defaultSelection ) ? defaultSelection.getAttribute( 'data-tab-id' ) : 1,
            defaultContent = null;


        // console.log( { defaultSelection, defaultIndex } );

        tabElements.forEach( element => {
            // console.log( { element, '': element.getAttribute( 'data-tab-id' ) } );
            if( element.getAttribute( 'data-tab-id' ) !== defaultIndex ) {
                element.classList.remove( CLASSNAME_ACTIVE );
            }
        } );

        Tennocon2024.utilityClearContentSelection( contentElements );
        if ( !!contentElements ) {
            defaultContent = contentElements.find( element =>  element.getAttribute( 'data-content-id' ) == defaultIndex );
            if( !!defaultContent ){
                defaultContent.classList.add( CLASSNAME_ACTIVE );
            }
        }

        if( !!updatableDisplayElements ) {
            updatableDisplayElements.forEach( el => {
                el.setAttribute( "data-selected", defaultSelection.textContent );
            } );
        }

        const SELECTOR_TAB_ELEMENT = '[ role="tab" ]';
        tabElements.forEach( ( item, index, array ) => {
            let self = item,
                button = item.closest( SELECTOR_TAB_ELEMENT ),
                menuParentElement = self.closest( ".collapsible-menu-ui" ),
                hasMenuParent = ( !!menuParentElement );

            // console.log( { self, menuParentElement, hasMenuParent } );

            if ( !button ) { return; }
            button.addEventListener( 'click', ( event ) => {
                event.preventDefault();
                let element = event.target.closest( SELECTOR_TAB_ELEMENT ),
                    parent = self,
                    reference,
                    target;
                if ( !element ) { return; }

                reference = element.getAttribute( "data-tab-id" );
                if ( !reference ) { return;}
                target = tabContent.querySelector( `[ data-content-id="${ reference }"]` );

                if ( !target ) { return; }
                Tennocon2024.utilityClearContentSelection( contentElements );
                target.classList.add( CLASSNAME_ACTIVE );

                // console.log( { item } );
                // item.addAttribute( 'hidden' );

                array.forEach( ( picker ) => {
                    let pickerButton = picker.closest( SELECTOR_TAB_ELEMENT );
                    picker.classList.remove( CLASSNAME_ACTIVE );
                    if ( !pickerButton ) { return; }
                    pickerButton.removeAttribute( "aria-selected" );
                } );
                parent.classList.add( CLASSNAME_ACTIVE );
                element.setAttribute( "aria-selected", true );

                if ( !!updatableDisplayElements ) {
                    updatableDisplayElements.forEach( el => {
                        // console.log( element.textContent );
                        el.setAttribute( "data-selected", element.textContent );
                    })
                }

                if ( hasMenuParent ) {
                    [ ... menuParentElement.querySelectorAll( '.panel' ) ].forEach( ( el ) => {
                        el.dispatchEvent( new CustomEvent( "panel-close" ) );
                    } );
                }

            } );
        } );
    },

    bindAccordionHandler( element ) {
        // console.log( "bindAccordionHandler", { element } );
        let toggle,
            content,
            CLASSNAME_OPEN = "open";
        toggle = element.querySelector( "[ data-accordion-toggle ]" );
        content = element.querySelector( "[ data-accordion-content ]" );
        if ( !toggle ) {
            // console.debug( "No ticket", { element } );
            return;
        }
        if ( !!content && toggle.classList.contains( CLASSNAME_OPEN ) ) {
            content.classList.add( CLASSNAME_OPEN );
            // "Once you start down the dark path, forever will it dominate your destiny, consume you it will."
        }
        toggle.addEventListener( "click", ( event ) => {
            // console.log( "toggle", {event, element} );
            toggle.classList.toggle( CLASSNAME_OPEN );
            if ( !content ) {
                return;
                // "The dark side of the Force is a pathway to many abilities some consider to be unnatural"
            }
            if ( toggle.classList.contains( CLASSNAME_OPEN ) ) {
                content.classList.add( CLASSNAME_OPEN );
            } else {
                content.classList.remove( CLASSNAME_OPEN );
            }

            if( element.classList.contains( CLASSNAME_OPEN ) ) {
                element.classList.remove( CLASSNAME_OPEN );
            } else {
                element.classList.add( CLASSNAME_OPEN );
            }
        } );

    },

    checkEventbriteWidget( interval, attemptsLeft ) {
        // console.log( 'checkEventbriteWidget', { interval, attemptsLeft } );
        if ( window.EBWidgets !== undefined) {
            // Object found, do something
            // console.log("window.EBWidgets found!");
        } else if (attemptsLeft > 0) {
            // Object not found, retry after interval
            setTimeout(function() {
                Tennocon2024.checkEventbriteWidget( interval, attemptsLeft - 1);
            }, interval );
        } else {
            // Object not found after all attempts, call onFooBarBazFail()
            // console.log("window.EBWidgets NOT found!");
            document.body.classList.add( "eventbrite-not-available" );
        }
    },

    init: (PageComponents, container=null) => {
        if (!container || !container.querySelectorAll) {
            container = document;
        }

        Tennocon2024.tabs = [
            document.querySelector( `${ SELECTOR_TICKET_UI }` ),
            document.querySelector( `${ SELECTOR_SCHEDULE_UI }` )
        ];


        if ( !!Tennocon2024.tabs ) {
            // console.log( { "Tabs": Tennocon2024.tabs } );


            // step in to set the default Schedule UI tab based on the anchor
            let anchorString = Tennocon2024.getCurrentPageAnchor().toLowerCase(),
                scheduleUITabs,
                anchorDerivedTabId = null;

            // console.log( {scheduleUITabs, anchorString} );

            if ( !!anchorString ) {

                // use a recognized anchor in the URL to set the default tab
                switch( anchorString ) {
                    case "maps":
                        anchorDerivedTabId = 3;
                        break;
                    case "day2":
                        anchorDerivedTabId = 2;
                        break;
                    case "day1":
                        anchorDerivedTabId = 1;
                        break;
                }

                if( !!anchorDerivedTabId ){
                    [ ... document.querySelectorAll( `${ SELECTOR_SCHEDULE_UI } [ data-tab-id ]` ) ].forEach( ( element, index ) => {
                        if ( element.getAttribute( "data-tab-id" ) == anchorDerivedTabId ) {
                            element.classList.add( "active" );
                            element.setAttribute( "aria-selected", true );
                        } else {
                            element.classList.remove( "active" );
                            element.removeAttribute( "aria-selected" );
                        }
                    } );

                    // console.log(  document.getElementById( "schedule" ) );
                    // document.getElementById( "schedule" ).scrollIntoView();
                    setTimeout( () => {
                        document.getElementById( "schedulesandmaps" ).scrollIntoView();
                    }, 500 );
                }




                // console.log( {anchorString} );
            }
            // bind tab behaviors
            Tennocon2024.tabs.forEach( element => { Tennocon2024.bindTabBehaviors( element ) } );
        }

        Tennocon2024.accordions = [ ...document.querySelectorAll( `${ SELECTOR_HANDLER_BINDING_ACCORDION }` ), ...document.querySelectorAll( SELECTOR_HANDLER_BINDING_FAQ ) ];
        // console.log( { 'Tennocon2024.accordions': Tennocon2024.accordions } );
        if ( !!Tennocon2024.accordions ) {
            // console.log( { "Accordions": Tennocon2024.accordions } );
            // bind accordion behaviors
            Tennocon2024.accordions.forEach(element => { Tennocon2024.bindAccordionHandler( element ) } );
        }

        // Activate Responsive Tooltips
        if( document.querySelectorAll( "[ data-tooltip ]" ) ) {
            ResponsiveTooltip.init();
        }

        // TODO: Get this out of here
        let menuPanels = [ ... document.querySelectorAll( ".collapsible-menu-ui" ) ];
        if (  !!menuPanels ) {
            menuPanels.forEach( element => {
                let self = element,
                    panelElements = [ ... element.querySelectorAll( '.panel' ) ],
                    CLASSNAME_ACTIVE = "active";
                panelElements.forEach( el => {
                    el.addEventListener( "click", event => {
                        event.preventDefault();
                        self.classList.toggle( CLASSNAME_ACTIVE );
                    } );
                    el.addEventListener("panel-close", event => {
                        event.preventDefault();
                        event.stopPropagation();
                        self.classList.remove( CLASSNAME_ACTIVE );
                    });
                    el.addEventListener("panel-open", event => {
                        event.preventDefault();
                        event.stopPropagation();
                        self.classList.add( CLASSNAME_ACTIVE );
                    });
                } );
            } );
        }



        // Tennocon2024.checkEventbriteWidget( 500, 10 ); // if we need async recursion

        PageComponents.Tennocon2024 = Tennocon2024;
    },

};

export { Tennocon2024 };
