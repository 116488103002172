import {EventBus} from "../../utility/vue-event-bus";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const PARENT_CLASS_SELECTOR = "#duviri-gameplay",
    CHILD_CLASS_SELECTOR = ".card";


function getAbsoluteHeight(el) {
    // Get the DOM Node if you pass in a string
    el = (typeof el === 'string') ? document.querySelector(el) : el;

    var styles = window.getComputedStyle(el);
    var margin = parseFloat(styles['marginTop']) +
        parseFloat(styles['marginBottom']);

    return Math.ceil(el.offsetHeight + margin);
}

const DuviriGameplayParallax = {
    initialized: false,
    elPinnedElement: null,
    arrayParallaxChildren: null,

    targetElement: document.querySelector( "#duviri-gameplay .sublayout .background-image" ),
    parentElement: document.querySelector( "#duviri-gameplay .sublayout" ),

    getHeaderElementsHeightInPixels() {
        let elementSelectors = [ ".SubNavigation", ".HeaderNavigationBar" ],
            heightInPixels = 0;
        elementSelectors.reduce( ( aggregator, selector) => {
            let element = document.querySelector( selector );
            if ( element ) {
                heightInPixels += element.getBoundingClientRect().height;
            }
            return heightInPixels;
        }, heightInPixels );
        return heightInPixels;
    },

    initParallaxRedux: () => {

        ScrollTrigger.create({
            trigger: DuviriGameplayParallax.parentElement,
            // markers: true,
            pin: DuviriGameplayParallax.targetElement,
            // invalidateOnRefresh: true,
            start ( scroller ) {
                let headerHeight = DuviriGameplayParallax.getHeaderElementsHeightInPixels();
                return `top ${ headerHeight }px`;
            },
            pinSpacing: false,
            endTrigger: DuviriGameplayParallax.parentElement.querySelector( '.cards .card.boons' ),
            end ( scroller ) {
                return `top center`;
            }
        });

    },

    onRecalculateParallax( ) {
        ScrollTrigger.refresh( true );
        setTimeout( () => {
            ScrollTrigger.refresh( true );
        }, 195 ); // trust issues
    },

    init: (PageComponents, container = null) => {

        if (!container || !container.querySelectorAll) {
            container = document;
        }
        DuviriGameplayParallax.elPinnedElement = container.querySelector(PARENT_CLASS_SELECTOR);

        /* RED FLAG!! PROBLEM HERE!! */
        if ( !DuviriGameplayParallax.elPinnedElement ) { return; }
        DuviriGameplayParallax.arrayParallaxChildren = [...DuviriGameplayParallax.elPinnedElement.querySelectorAll(CHILD_CLASS_SELECTOR)];

        if (!DuviriGameplayParallax.elPinnedElement || DuviriGameplayParallax.arrayParallaxChildren.length < 1) {
            return;  // :(
        }

        EventBus.$on( "recalculate-parallax", DuviriGameplayParallax.onRecalculateParallax );
        DuviriGameplayParallax.initParallaxRedux();

        if (!DuviriGameplayParallax.initialized) {
            DuviriGameplayParallax.initialized = true;
        }

        PageComponents.DuviriGameplayParallax = DuviriGameplayParallax;
    }

};

export {DuviriGameplayParallax};
