const CLASS_SELECTOR = ".Sticky";

function calcHeight(raw) {
    if (typeof(raw) === "undefined" || raw === null) return 10000;
    if (raw.endsWith('vh')) {
        return window.innerHeight * (raw.substring(0, raw.length - 2)) / 100;
    }
    return raw;
}

const Sticky = {
    elements: null,
    initialized: false,
    lastAnimationFrame: 0,

    setFixed: (element, contentElem, elementRect, contentHeight, stickyHeight=0) => {
        contentElem.style.left = elementRect.left + 'px';
        contentElem.style.right = 'auto';
        contentElem.style.width = elementRect.width + 'px';
        if (stickyHeight > 0) {
            contentElem.style.height = stickyHeight + 'px';
        } else {
            contentElem.style.height = null;
        }
        element.style.height = contentHeight + 'px';
    },
    clearFixed: (element, contentElem) => {
        contentElem.style.left = null;
        contentElem.style.right = null;
        contentElem.style.top = null;
        contentElem.style.bottom = null;
        contentElem.style.width = null;
        contentElem.style.height = null;
        element.style.height = null;
    },

    update: () => {
        const navContainer = document.getElementById("header");
        let topOffset = 0;
        if (navContainer) {
            const navBar = navContainer.querySelector(".HeaderNavigationBar");
            if (navBar) {
                topOffset += navBar.getBoundingClientRect().height;
            }
        }

        let bottomOffset = 0;
        let topMarginOffset = topOffset;

        window.forEachNode(Sticky.elements, (element, index) => {
            if (!element || !element.getBoundingClientRect) return;
            element.classList.toggle('is-fixed', false);
            const contentElem = element.querySelector('.Sticky-content');
            contentElem.style.width = null;
            contentElem.style.height = null;
            const skipFootprint = element.getAttribute("data-footprint") === "false";

            const elementRect = element.getBoundingClientRect();
            const contentHeight = contentElem.offsetHeight;
            const stickyHeight = Math.min(calcHeight(element.getAttribute('data-max-sticky-height')), contentHeight);

            const anchor = element.getAttribute('data-anchor');
            const direction = element.getAttribute('data-direction');
            const isReversed = direction === "reverse";
            // Bottom anchor
            if (anchor === "bottom") {
                const bottomTogglePoint = elementRect.bottom - contentHeight + stickyHeight;
                const isSticky = skipFootprint || ((bottomTogglePoint > window.innerHeight - bottomOffset) ^ isReversed);
                element.classList.toggle('is-fixed', isSticky);
                if (isSticky) {
                    Sticky.setFixed(
                        element,
                        contentElem,
                        elementRect,
                        skipFootprint ? 0 : contentHeight,
                        stickyHeight < contentHeight ? stickyHeight : 0
                    );
                    contentElem.style.top = null;
                    if (element.getAttribute("data-ignore-offset") === "true") {
                        contentElem.style.bottom = '0';
                    } else {
                        contentElem.style.bottom = bottomOffset + 'px';
                        bottomOffset += stickyHeight;
                    }
                } else {
                    Sticky.clearFixed(element, contentElem);
                }
            }
            // Top anchor
            else {
                const topTogglePoint = elementRect.top + contentHeight - stickyHeight;
                const isSticky = skipFootprint || ((topTogglePoint < topOffset) ^ isReversed);
                element.classList.toggle('is-fixed', isSticky);
                if (isSticky) {
                    Sticky.setFixed(
                        element,
                        contentElem,
                        elementRect,
                        skipFootprint ? 0 : contentHeight,
                        stickyHeight < contentHeight ? stickyHeight : 0
                    );
                    if (element.getAttribute("data-ignore-offset") === "true") {
                        contentElem.style.top = '0';
                    } else {
                        contentElem.style.top = topOffset + 'px';
                        topOffset += stickyHeight;
                    }
                    contentElem.style.bottom = null;
                } else {
                    Sticky.clearFixed(element, contentElem);
                }
                topMarginOffset += stickyHeight;
            }
        });
        document.querySelector('body').style.setProperty("--page-header-height", topMarginOffset + "px");
    },

    scrollHandler: (event) => {
        requestAnimationFrame((timestamp) => {
           if (timestamp > Sticky.lastAnimationFrame) {
               Sticky.lastAnimationFrame = timestamp;
               Sticky.update();
           }
        });
    },

    init: (PageComponents, container=null) => {
        if (!container || !container.querySelectorAll) {
            container = document;
        }
        Sticky.elements = container.querySelectorAll(CLASS_SELECTOR);
        if (!Sticky.initialized) {
            Sticky.initialized = true;
            window.addEventListener('scroll', Sticky.scrollHandler);
            window.addEventListener('resize', Sticky.scrollHandler);
        }
        Sticky.update();

        PageComponents.Sticky = Sticky;
    },

};

module.exports = Sticky;
