window.Warframe = window.Warframe || {};

const hoverMatchList = window.matchMedia( "(hover: hover)" ),
    mobileMatchList = window.matchMedia("(max-width: 767px)");


// Check if the device supports touch events
function isTouchDevice() {
    return 'ontouchstart' in window || (navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
}

// Check if the device is not using a mouse
function isNotMouseDevice() {
    return matchMedia('(hover: none)').matches;
}

// Apply classes based on touch and mouse device detection
if (isTouchDevice() && isNotMouseDevice()) {
    // This device has touch support and is not using a mouse
    document.documentElement.classList.add('touch-device');
    // console.log( `%cNo Hover!`, `font-size: 24px; color: red` );
    // console.log( `%cThis device has touch support and is not using a mouse`, `font-size: 18px; color: red` );

} else {
    // This device does not have touch support or is using a mouse
    document.documentElement.classList.add('no-touch-device');
    // console.log( `%cHover!`, `font-size: 24px; color: green` );
    // console.log( `%cThis device does not have touch support or is using a mouse`, `font-size: 18px; color: green` );

}




const PowerPacks = {
    elShowcase: null,
    elShowcaseDialog: null,
    elDialogContent: null,
    ShowcaseDialogIsOpen: false,
    elCurrentCard: null,
    setDialogContents() {
        let self = PowerPacks,
            elSource = self.elCurrentCard.querySelector( "figcaption" ),
            elTarget = self.elDialogContent;

        // console.log( 'setDialogContents', { elSource, "source children": elSource.childNodes, elTarget } );


        return new Promise( resolve => {
            elTarget.innerHTML = "";

            [ ... elSource.childNodes ].forEach( ( node, index ) => {
                elTarget.appendChild( node.cloneNode( true ) );
            } );

            resolve( true );
        } );
    },
    resolveAfter2Seconds() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve('resolved');
            }, 2000);
        })
    },
    unsetDialogContents() {
        PowerPacks.elDialogContent.innerHTML = "";
        PowerPacks.elCurrentCard = null;
    },
    openDialog: async function() {
        // console.log( "openDialog" );
        let self = PowerPacks,
        awaitClone = await self.setDialogContents();
        // console.log( "openDialog Continues", { awaitClone } );

        self.elShowcaseDialog.setAttribute( "open", "true" );
        self.ShowcaseDialogIsOpen = true;
        self.elShowcase.classList.add( "dialog-is-open" );
    },
    closeDialog() {
        let self = PowerPacks;
        self.elShowcaseDialog.removeAttribute( "open" );
        self.ShowcaseDialogIsOpen = false;
        self.elShowcase.classList.remove( "dialog-is-open" );

        // reset content
        self.unsetDialogContents();
    },
    updateShowcaseVariables() {
        const STRING_VARIABLE_OFFSET_PREFIX = "--power-pack-showcase-row-offset-nth",
            STRING_VARIABLE_DURATION_PREFIX = "--power-pack-showcase-row-duration",
            DURATION_IN_SECONDS = 60,
            RATE_SECONDS_PER_PIXEL = DURATION_IN_SECONDS / 1440;
        let rows = [ ... PowerPacks.elShowcase.querySelectorAll( ".sublayout .grid" ) ],
            parent = PowerPacks.elShowcase.querySelector( ".sublayout" ),
            sampleCard = PowerPacks.elShowcase.querySelector( ".sublayout .grid .card" );

        rows.forEach( ( element, index ) => {
            let computedDifference = ( element.getBoundingClientRect().width + sampleCard.getBoundingClientRect().width ) - parent.getBoundingClientRect().width;

            document.documentElement.style.setProperty( `${ STRING_VARIABLE_OFFSET_PREFIX }-${ index + 1 }`, `${ computedDifference * 0.5 }px` );

            document.documentElement.style.setProperty( `${ STRING_VARIABLE_DURATION_PREFIX }-${ index + 1 }`, `${ computedDifference * RATE_SECONDS_PER_PIXEL }s` );


        } );
    },
    onDelegatedCardClick( event ) {
        let self = PowerPacks,
            elCard,
            isCard,
            isDialogBox,
            isDialogClose;
        if ( event.target ) {
            // console.log("Click event detected");

            elCard = event.target.closest( ".sublayout .card" );
            isCard = !!elCard;
            isDialogClose = !!event.target.closest( "#card-detail button" );
            isDialogBox = !!event.target.closest( "#card-detail" )

            // console.log( { isCard, "hover?": hoverMatchList.matches } );
            if ( isCard ) {

                if ( mobileMatchList.matches ) {
                    // open dialog
                    // console.log( `%cOPEN THE DIALOG BAY DOORS`, `font-size: 24px; color: green` );
                    self.elCurrentCard = elCard;
                    self.openDialog();
                } /* else {
                    console.log( `%cI'm sorry, Dave`, `font-size: 24px; color: red` );
                } */

            } else if ( !isDialogBox ) {
                if ( self.ShowcaseDialogIsOpen ) {
                    self.closeDialog();
                }
            }

            if ( isDialogClose ) {
                if ( self.ShowcaseDialogIsOpen ) {
                    self.closeDialog();
                }
            }





            // e.target.matches("a.classA")
        }

    },
    init: (PageComponents, container=null) => {
        if (!container || !container.querySelectorAll) {
            container = document;
        }

        let self = PowerPacks;

        self.elShowcase = document.querySelector( "#power-pack-showcase" );
        self.elShowcaseDialog = self.elShowcase.querySelector( "#card-detail" );
        self.elDialogContent = self.elShowcaseDialog.querySelector( ".content" );

        // Bind resize event handler
        window.addEventListener( "resize", self.updateShowcaseVariables );
        self.updateShowcaseVariables();

        // Bind Card Clicks (conditionally)
        window.addEventListener( "click", self.onDelegatedCardClick );

        self.elShowcase.classList.add( "ready" );

        PageComponents.PowerPacks = PowerPacks;
    }
};

export { PowerPacks };
