// DeferredBlock behavior
const DeferredBlock = {
    COMPONENT_CLASS: "DeferredBlock",
    COMPONENT_INNER_CLASS: "DeferredBlock-inner",
    ANIMATING_CLASS: "is-animating",
    MOVING_CLASS: "is-moving",
    FADING_CLASS: "is-fading",
    HIDDEN_CLASS: "is-hidden",
    snapshot: (elem, options = {}) => {
        let {forMoving=false, forFading=false} = options;
        if (elem) {
            const classList = elem.classList;
            if (!classList.contains(DeferredBlock.COMPONENT_CLASS) || classList.contains(DeferredBlock.ANIMATING_CLASS)) return;
            const innerElem = elem.querySelector(`.${DeferredBlock.COMPONENT_INNER_CLASS}`);
            const innerRect = innerElem.getBoundingClientRect();
            const parentElem = innerElem.offsetParent;
            if (parentElem) {
                const parentRect = parentElem.getBoundingClientRect();
                const left = innerRect.left - parentRect.left;
                const top = innerRect.top - parentRect.top;
                const width = innerRect.width;
                const height = innerRect.height;

                innerElem.style.left = left + "px";
                innerElem.style.top = top + "px";
                if (forMoving) {
                    elem.style.width = width + "px";
                    elem.style.height = height + "px";
                    innerElem.style.width = width + "px";
                    innerElem.style.height = height + "px";
                } else if (forFading) {
                    innerElem.style.width = width + "px";
                    innerElem.style.height = height + "px";
                }
            } else {
                console.error("Error: attempted to snapshot non-visible DeferredBlock", elem);
            }
        }
    },
    animate: (elem, options={}) => {
        let {forMoving=false, forFading=false} = options;
        if (elem) {
            const classList = elem.classList;
            if (!classList.contains(DeferredBlock.COMPONENT_CLASS) || classList.contains(DeferredBlock.ANIMATING_CLASS)) return;
            const innerElem = elem.querySelector(`.${DeferredBlock.COMPONENT_INNER_CLASS}`);
            const outerRect = elem.getBoundingClientRect();
            classList.add(DeferredBlock.ANIMATING_CLASS);
            if (forMoving) {
                classList.add(DeferredBlock.MOVING_CLASS);

                const parentElem = innerElem.offsetParent;
                if (parentElem) {
                    const parentRect = parentElem.getBoundingClientRect();
                    const left = outerRect.left - parentRect.left;
                    const top = outerRect.top - parentRect.top;
                    /*
                    // fixme: replace with a better library
                    innerElem.velocity({
                        left: left + "px",
                        top: top + "px"
                    }, {
                        duration: 250,
                        complete: () => {
                            DeferredBlock.restore(elem);
                        }
                    });
                     */
                    $(innerElem).animate({
                        left: left + "px",
                        top: top + "px"
                    }, {
                        duration: 250,
                        complete: () => {
                            DeferredBlock.restore(elem);
                        }
                    });
                }
            } else if (forFading) {
                classList.add(DeferredBlock.FADING_CLASS);
            }
        }
    },
    restore: (elem, options={}) => {
        let {fromFading = false} = options;
        if (elem) {
            const classList = elem.classList;
            if (!classList.contains(DeferredBlock.COMPONENT_CLASS) || !classList.contains(DeferredBlock.ANIMATING_CLASS)) return;
            const innerElem = elem.querySelector(`.${DeferredBlock.COMPONENT_INNER_CLASS}`);

            innerElem.style.left = null;
            innerElem.style.top = null;
            elem.style.width = innerElem.style.width = null;
            elem.style.height = innerElem.style.height = null;
            classList.remove(
                DeferredBlock.ANIMATING_CLASS,
                DeferredBlock.MOVING_CLASS,
                DeferredBlock.FADING_CLASS
            );
            if (fromFading) {
                classList.add(DeferredBlock.HIDDEN_CLASS);
            }
        }
    },
    init: (PageComponents, container=null) => {
        PageComponents.DeferredBlock = DeferredBlock;
    }
}

module.exports = DeferredBlock;
