const CLASS_SELECTOR = ".CollapsibleFAQ";
const ANSWER_SELECTOR = ".CollapsibleFAQ-answer";
const INNER_SELECTOR = ".CollapsibleFAQ-answerInner";

const CollapsibleFAQ = {
    elements: null,

    toggle: (element) => {
        element.classList.toggle('is-open');
        const container = element.querySelector(ANSWER_SELECTOR);
        const inner = element.querySelector(INNER_SELECTOR);
        container.style.height = ((element.classList.contains('is-open')) ? inner.offsetHeight : 0) + 'px';
    },

    initElements: (container) => {
        if (!container || !container.querySelectorAll) {
            container = document;
        }
        const elements = container.querySelectorAll(CLASS_SELECTOR);
        window.forEachNode(elements, (element, index) => {
            if (!element.hasAttribute('data-initialized')) {
                element.addEventListener(
                    'click',
                    CollapsibleFAQ.toggle.bind(null, element)
                );
                element.setAttribute('data-initialized', 'true');
            }
        });
        CollapsibleFAQ.elements = document.querySelectorAll(CLASS_SELECTOR);
    },

    init: (PageComponents, container=null) => {
        CollapsibleFAQ.initElements(container);

        PageComponents.CollapsibleFAQ = CollapsibleFAQ;
    },
};

module.exports = CollapsibleFAQ;
