
const SubNavigation = {
    elements: [],
    handlers: {},
    init: (PageComponents, container=null) => {
        SubNavigation.elements = document.querySelectorAll('.SubNavigation');
        window.forEachNode(SubNavigation.elements, (subNavElem, index) => {
            const globalNavContainer = document.querySelector('.containWrap, .HeaderNavigationBar');
            // todo: remove me after new nav goes live
            const mobileNavCover = document.querySelector('.mobileLogo');
            const subNavWrapper = subNavElem.querySelector('.SubNavigation-wrapper');
            const subNavScroller = subNavElem.querySelector('.SubNavigation-scroller');
            const subNavContent = subNavElem.querySelector('.SubNavigation-content');
            const subNavUnderline = subNavElem.querySelector('.SubNavigation-underline');

            // Sync nav with page section
            let pageSections = [];
            function checkActiveSection() {
                requestAnimationFrame(() => {
                    const midpoint = window.innerHeight / 2;
                    pageSections.forEach((token, index) => {
                        const anchor = subNavElem.querySelector('.SubNavigationItem[data-target="' + token + '"]');
                        const section = document.getElementById(token).getBoundingClientRect();
                        if (anchor) {
                            let isActive;
                            if (index === 0) {
                                isActive = midpoint < section.bottom;
                            } else if (index === (pageSections.length - 1)) {
                                isActive = section.top < midpoint;
                            } else {
                                isActive = section.top < midpoint && midpoint < section.bottom;
                            }
                            if (isActive && !anchor.classList.contains('is-active')) {
                                anchor.classList.add('is-active');
                                moveSubNavUnderline(anchor);
                            } else {
                                anchor.classList.toggle('is-active', isActive);
                            }
                        }
                    });
                });
            }

            function subNavScrollHandler() {
                checkActiveSection();
                subNavElem.style.height = subNavWrapper.offsetHeight + 'px';

                const globalNavBox = globalNavContainer.getBoundingClientRect();
                let navBottom = globalNavBox.bottom;
                // fixme: remove me with new nav in place
                if (mobileNavCover) {
                    const mobileNavStyle = window.getComputedStyle(document.querySelector('.mobileHeader'));
                    if (mobileNavStyle.display !== 'none') {
                        navBottom = mobileNavCover.getBoundingClientRect().bottom;
                    }
                }
                const subNavBox = subNavElem.getBoundingClientRect();
                if (subNavBox.top < navBottom) {
                    subNavElem.classList.add('is-fixed');
                    subNavElem.querySelector('.SubNavigation-wrapper').style.top = navBottom + 'px';
                } else {
                    subNavElem.classList.remove('is-fixed');
                    subNavElem.querySelector('.SubNavigation-wrapper').style.top = null;
                }
            }
            document.addEventListener("scroll", subNavScrollHandler);

            // Align underline on page sub-nav
            function moveSubNavUnderline(element, animate = true) {
                moveSubNavUnderlineTo(element.offsetLeft, element.offsetWidth, animate);
            }
            function moveSubNavUnderlineTo(left, width, animate = true) {
                if (animate) {
                    // todo: get rid of jQuery
                    $(subNavUnderline).stop().animate(
                        {
                            left : left+'px',
                            width: width+'px'
                        },
                        250
                    );
                } else {
                    subNavUnderline.style.left = left+'px';
                    subNavUnderline.style.width = width+'px';
                }
            }

            let navAnchors = subNavElem.querySelectorAll('.SubNavigationItem');
            for (const el of navAnchors) {
                if (el.dataset.target && !pageSections.includes(el.dataset.target)) {
                    pageSections.push(el.dataset.target);
                }
            }
            subNavScrollHandler();

            let activeSubNavItem = subNavElem.querySelector('.SubNavigationItem.is-active');
            if (activeSubNavItem) {
                moveSubNavUnderline(activeSubNavItem, false);
            }
            for (let i = 0; i < navAnchors.length; i++) {
                if (navAnchors[i].classList.contains('is-active')) continue;

                navAnchors[i].addEventListener("mouseenter", function () {
                    moveSubNavUnderline(navAnchors[i]);
                });
                navAnchors[i].addEventListener("mouseleave", function () {
                    const activeElem = subNavElem.querySelector('.SubNavigationItem.is-active');
                    if (activeElem) {
                        moveSubNavUnderline(activeElem);
                    }
                });
            }

            function subNavResizeHandler() {
                subNavElem.classList.toggle('is-collapsed', subNavWrapper.offsetWidth < subNavContent.offsetWidth);
                const activeElem = subNavElem.querySelector('.SubNavigationItem.is-active');
                if (activeElem) {
                    moveSubNavUnderline(activeElem, false)
                }
            }
            window.addEventListener("resize", subNavResizeHandler);
            subNavResizeHandler();

            subNavElem.querySelector('.SubNavigation-left').addEventListener('click', (e) => {
                e.stopPropagation();
                e.preventDefault();
                subNavScroller.scrollLeft -= 40;
            });
            subNavElem.querySelector('.SubNavigation-right').addEventListener('click', (e) => {
                e.stopPropagation();
                e.preventDefault();
                subNavScroller.scrollLeft += 40;
            });

            SubNavigation.handlers[index] = {
                mobileNavCover,
                subNavWrapper,
                subNavScroller,
                subNavContent,
                subNavUnderline,
                moveSubNavUnderline,
                moveSubNavUnderlineTo,
                subNavResizeHandler,
                subNavScrollHandler,
            }
        });

        PageComponents.SubNavigation = SubNavigation;
    }
}

module.exports = SubNavigation;
