const SVG_NAMESPACE = "http://www.w3.org/2000/svg";
const SPLASH_DURATION = 2000;
const DEBOUNCE_AMOUNT = 1000 / 60;
const SPLASH_WEIGHT = 0.04;

const CLASS_SELECTOR = ".Duviri";
const FILTER_SELECTOR = ".Duviri-filter feColorMatrix";
const MASK_SELECTOR = "mask.Duviri-mask";
const SPLASH_CONTAINER = ".Duviri-svg defs";
const SPLASH_SELECTOR = ".Duviri-clipMask";

const Duviri = {
    elements: null,
    handlers: [],
    initialized: false,
    lastAnimationFrame: 0,
    lastAnimationFrames: [],

    initElements: () => {
        window.forEachNode(Duviri.elements, (element, index) => {
            Duviri.initElement(element, index)
        });
    },
    initElement: (element, index) => {
        //console.log( {element, "width": element.getBoundingClientRect().width, "height": element.getBoundingClientRect().height } );
        if (!element.hasAttribute('data-initialized')) {
            element.setAttribute('data-initialized', 'true');
            const componentId = element.getAttribute("id");
            const mask = element.querySelector(MASK_SELECTOR);
            const grayscale = element.querySelector(FILTER_SELECTOR);

            // Copy splashes from first instance of component
            if (index > 0) {
                const originals = Duviri.elements[0].querySelectorAll(SPLASH_SELECTOR);
                //console.log({originals});
                const container = element.querySelector(SPLASH_CONTAINER);
                let newSplash;
                window.forEachNode(originals, (original, i) => {
                    newSplash = original.cloneNode(true);
                    newSplash.id = `Duviri-clip${i+1}-${componentId}`;
                    container.appendChild(newSplash);
                    //console.log(newSplash);
                });
            }

            const splashElements = element.querySelectorAll(SPLASH_SELECTOR);
            const splashOptions = [];
            for (let i = 0; i < splashElements.length; i ++) {
                splashOptions.push(splashElements[i].getAttribute("id"));
            }

            const shapes = [];
            let lastShape = null;
            let lockout = 0;
            let decayLockout = 0;
            let weightOffset = 0.0;
            let lockedIn = false;
            let lockedAlpha = 0.0;
            let debounceLockout = 0;

            function tick() {
                let i, shape, progress;
                let weight = weightOffset;
                const timestamp = Date.now();
                if (lockedIn) {
                    lockedAlpha = Math.min(1.0, lockedAlpha + 0.01);
                    grayscale.setAttribute("values", lockedAlpha);
                }
                for(i = 0; i < shapes.length; i ++) {
                    shape = shapes[i];
                    if (shape === lastShape) {
                        if (timestamp < decayLockout) {
                            shape.timestamp = timestamp;
                            lastShape = shape;
                            weight += SPLASH_WEIGHT;
                            continue;
                        } else {
                            lastShape = null;
                        }
                    }

                    progress = (timestamp - shape.timestamp) / SPLASH_DURATION;
                    if (progress > 1.0) {
                        shapes.splice(i, 1);
                        mask.removeChild(shape.element);
                        i --;
                    } else {
                        shape.element.setAttribute('opacity', 1.0 - progress);
                        weight += SPLASH_WEIGHT * (1.0 - progress);
                    }
                }
                //console.log(weight, weightOffset);

                if (weight > 0.65) {
                    lockedIn = true;
                }
                if (shapes.length > 0 || (lockedIn && lockedAlpha < 1.0)) {
                    setTimeout(
                        () => requestAnimationFrame(tick),
                        DEBOUNCE_AMOUNT
                    );
                }
                if (shapes.length > 0) {
                    weightOffset = Math.max(0.0, weightOffset - 0.0015);
                } else {
                    weightOffset = 0.0;
                }
            }
            function generateSplash(x, y, width, height, timestamp, size=1.0, persistent=true, context = element) {



                if (lockedIn) return;

                let viewBox = context.querySelector( "svg" ).getAttribute( "viewBox" ),
                    basisWidth = viewBox.split( ' ' )[ 2 ],
                    basisHeight = viewBox.split( ' ' )[ 3 ];

                // let ds = 0.0;
                // let ds = Math.random() - 0.5;
                // let dScale = ds + 1.0;
                let cOffset = 150;
                let dx = (x / width);
                let dy = (y / height);
                // let tx = 1980.0 * dx;
                // let ty = 1080.0 * dy;

                let tx = basisWidth * dx;
                let ty = basisHeight * dy;


                // let xOffset = (tx) * ds;
                // let yOffset = (ty) * ds;
                // let xOffset = (tx);
                // let yOffset = (ty);
                let cx = tx - cOffset;
                let cy = ty - cOffset;
                let dr = 360.0 * Math.random();
                const element = document.createElementNS(SVG_NAMESPACE, 'use');
                element.setAttribute('x', "0");
                element.setAttribute('y', "0");
                element.setAttribute('href', '#' + splashOptions[Math.floor(Math.random() * splashOptions.length)]);
                element.setAttribute('fill', 'white');
                // const transform = `translate(-${cOffset} -${cOffset}) scale(${dScale}) translate(${cx} ${cy}) rotate(${dr} ${cOffset} ${cOffset})`;
                const transform = `translate(${cx} ${cy}) rotate(${dr} ${cOffset} ${cOffset})`;
                element.setAttribute('transform', transform);

                mask.appendChild(element);
                if (shapes.length < 1) {
                    requestAnimationFrame(tick);
                }
                shapes.push(lastShape = {
                    element,
                    timestamp,
                    size
                });
                lockout = timestamp + 50;
                decayLockout = timestamp + 3000;
                weightOffset += 0.01;
                if (!persistent) lastShape = null;
            }

            function debounceHandler(fn) {
                const now = Date.now();
                if (now > debounceLockout) {
                    requestAnimationFrame(fn);
                    debounceLockout = now + DEBOUNCE_AMOUNT;
                }
            }

            function hoverHandler(event) {
                debounceHandler(() => {
                    const timestamp = Date.now();
                    if (timestamp < lockout) return;


                    let rect = event.target.getBoundingClientRect();
                    let x = event.clientX - rect.left;
                    let y = event.clientY - rect.top;
                    let width = rect.width;
                    let height = rect.height;

                    generateSplash(x, y, width, height, timestamp);
                });
            }
            function blurHandler() {
                lastShape = null;
            }
            function tapHandler(event) {
                debounceHandler(() => {
                    const timestamp = Date.now();
                    if (timestamp < lockout) return;

                    let rect = event.target.getBoundingClientRect();
                    let touch = event.touches[0];
                    let x = touch.clientX - rect.left;
                    let y = touch.clientY - rect.top;
                    let width = rect.width;
                    let height = rect.height;

                    generateSplash(x, y, width, height, timestamp, 2.0, false);

                    lockedIn = true; // short-circuit Mobile at first tap
                });
            }

            element.addEventListener("mousemove", hoverHandler);
            element.addEventListener("mouseout", blurHandler);
            element.addEventListener("touchstart", tapHandler);

            Duviri.handlers.push({
                componentId,
                element,
                splashOptions,
                tick,
                generateSplash,
                debounceHandler,
                hoverHandler,
                blurHandler,
                tapHandler
            });
        }
    },

    init: (PageComponents, container=null) => {
        if (!container || !container.querySelectorAll) {
            container = document;
        }
        Duviri.elements = container.querySelectorAll(CLASS_SELECTOR);
        Duviri.initElements();
        if (!Duviri.initialized) {
            window.addEventListener('resize', Duviri.resizeHandler);
            Duviri.initialized = true;
        }

        PageComponents.Duviri = Duviri;
    },

};

module.exports = Duviri;
