const CLASS_SELECTOR = ".Parallax";
const LAYER_SELECTOR = ".ParallaxLayer";

const instances = [];
let initialized = false;
let lastTimestamp = 0;

function inverseLerp(a, b, value) {
    if (a !== b) {
        return (value - a) / (b - a);
    }
    return 0;
}
function syncAll(timestamp) {
    if (lastTimestamp === timestamp) return;
    lastTimestamp = timestamp;
    instances.forEach((instance) => instance.sync());
}
function updateHandler() {
    window.requestAnimationFrame(syncAll);
}

function init(PageComponents, container = null) {
    if (!container || !container.querySelectorAll) {
        container = document;
    }
    const elements = container.querySelectorAll(CLASS_SELECTOR);
    window.forEachNode(elements, (element, index) => {
        if (!element.hasAttribute('data-initialized')) {
            element.setAttribute('data-initialized', 'true');

            const layerElements = element.querySelectorAll(LAYER_SELECTOR);
            const layers = [];
            window.forEachNode(layerElements, (layer) => {
                const scale= parseFloat(layer.getAttribute("data-scale"));
                layer.style.height = (100.0 * scale) + '%';
                layers.push({
                    element: layer,
                    scale,
                    padding: (scale - 1) / scale,
                });
            });

            function inView(rect) {
                return (rect.bottom > 0 || rect.top < window.innerHeight);
            }

            function sync() {
                const rect = element.getBoundingClientRect();
                if (inView(rect)) {
                    layers.forEach((layer) => {
                        //const layerRect = layer.element.getBoundingClientRect();
                        const ceil = 0 - rect.height;
                        const floor = rect.bottom;
                        const fraction = 1.0 - inverseLerp(floor, ceil, rect.top);
                        const percent = layer.padding * -fraction
                        layer.element.style.transform = `translateY(${100.0 * percent}%)`;
                    });
                }
            }

            instances.push({
                layers,
                sync,
            });

            setTimeout(sync, 500);
        }
    });


    if (!initialized) {
        initialized = true;
        document.addEventListener("scroll", updateHandler);
        document.addEventListener("resize", updateHandler);
    }
}

module.exports = {
    instances,
    updateHandler,
    init
};
